import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LogisticBanner from 'pages/services/logistic/banner';
import LogisticInfo from 'pages/services/logistic/info';
import LogisticChar from 'pages/services/logistic/char';
import TechnologyBanner from 'pages/services/technology/banner';
import TechnologyInfo from 'pages/services/technology/info';
import TechnologyChar from 'pages/services/technology/char';
import CompaniesPage from 'pages/companies';
export default function ServciesPage() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="main__page main__page--full">
                <section className="nv__section nv__service__page">
                    <LogisticBanner />
                    <LogisticInfo />
                    <LogisticChar />

                    <TechnologyBanner />
                    <TechnologyInfo />
                    <TechnologyChar />

                    <CompaniesPage />
                </section>
            </main>
        </>
    );
}
