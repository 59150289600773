import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import { IoIosMail } from 'react-icons/io';
import { MdOutlinePhonelinkRing } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export default function ContactInfo() {
    const { t } = useTranslation();
    return (
        <div className="nv__contact__form__block nv__contact__form__block--info">
            <div className="contact__data">
                <div className="contact__data__container">
                    <div className="contact__data__icon">
                        <MdLocationOn />
                    </div>
                    <div className="contact__data__text">
                        <p>
                            <strong>{t('contactForm.address')}</strong>
                        </p>
                        <p>Calle 74 | San Francisco</p>
                        <p> PH Midtown - Suite 1405</p>
                        <p>{t('generals.address')}</p>
                    </div>
                </div>
            </div>

            <div className="contact__data">
                <div className="contact__data__container">
                    <div className="contact__data__icon">
                        <MdOutlinePhonelinkRing />
                    </div>
                    <div className="contact__data__text">
                        <p>
                            <strong>{t('contactForm.phone')}</strong>
                        </p>
                        <p>+507 277 8888</p>
                    </div>
                </div>
            </div>

            {/* <div className="contact__data">
                <div className="contact__data__container">
                    <div className="contact__data__icon">
                        <MdLocationOn />
                    </div>
                    <div className="contact__data__text">
                        <p>
                            <strong>{t('contactForm.address')}</strong>
                        </p>
                        <p>San Francisco</p>
                        <p> PH Midtown - 1405</p>
                        <p>{t('contactForm.country')}</p>
                    </div>
                </div>
            </div> */}

            <div className="contact__data">
                <div className="contact__data__container">
                    <div className="contact__data__icon">
                        <IoIosMail />
                    </div>
                    <div className="contact__data__text">
                        <p>
                            <strong>
                                {' '}
                                <strong>{t('contactForm.email')}</strong>
                            </strong>
                        </p>
                        <p>info@narval.com</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
