import React from 'react';
import bannerImage from 'assets/images/services-pages/technology/tecnology-2.jpg';
import service1 from 'assets/images/services-pages/technology/01-VISIBILITY.png';
import service2 from 'assets/images/services-pages/technology/02-PROGRESSION.png';
import service3 from 'assets/images/services-pages/technology/03-CONTROL.png';
import Animated from 'components/generals/AnimatedComponent';
import { useTranslation } from 'react-i18next';

export default function Char() {
    const { t } = useTranslation();
    return (
        <section
            className="nv__service__page__char"
            style={{
                backgroundImage: `url(${bannerImage})`,
            }}
        >
            <div className="nv__overlay nv__overlay--dark-5">
                <Animated animation={'animate__fadeInUp '}>
                    <section className="nv__section__container">
                        {/* <h1>{t('servicesPage.technology.characteristics')}</h1> */}
                        <p>{t('servicesPage.technology.d.characteristics')}</p>
                        <p>{t('servicesPage.technology.d.characteristics2')}</p>

                        {/* <div className="services__blocks">
                            <div className="services__blocks__items">
                                <div className="services__blocks__image">
                                    <img src={service1} alt="Service 1" />
                                </div>

                                <p className="services__blocks__title">
                                    {t('servicesPage.technology.service1')}
                                </p>
                            </div>

                            <div className="services__blocks__items">
                                <div className="services__blocks__image">
                                    <img src={service2} alt="Service 1" />
                                </div>

                                <p className="services__blocks__title">
                                    {t('servicesPage.technology.service2')}
                                </p>
                            </div>

                            <div className="services__blocks__items">
                                <div className="services__blocks__image">
                                    <img src={service3} alt="Service 1" />
                                </div>

                                <p className="services__blocks__title">
                                    {t('servicesPage.technology.service3')}
                                </p>
                            </div>
                        </div> */}
                    </section>
                </Animated>
            </div>
        </section>
    );
}
