import React, { useState, useEffect } from 'react';
import headerLogo from 'assets/images/logos/logo-white-blue.png';
import PTGLogo from 'assets/images/logos/ptg-white.png';
import panamericanLogoWhite from 'assets/images/logos/panamerican-white.png';
import metroLogo from 'assets/images/logos/metro.png';
import harborLogo from 'assets/images/logos/harbor.webp';
import ptyssLogo from 'assets/images/logos/PTYSS.png';
import refeerLogo from 'assets/images/logos/Reefer.webp';
import beermarkt from 'assets/images/logos/beermarkt.png';
import donnaStellaLogo from 'assets/images/logos/estela.png';
import Menu from 'components/ui/menu';
import ActionButton from 'components/ui/buttons/action-button';
import { useTranslation } from 'react-i18next';
import { IoMdMenu } from 'react-icons/io';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal } from 'reduxStore/slices/contact.slice';
import { Link } from 'react-router-dom';
import Animated from 'components/generals/AnimatedComponent';
import { setDisplayMobileMenu } from 'reduxStore/slices/contact.slice';
import { FaLinkedin } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import LanguangeSelector from 'components/generals/language-selector';

export default function MainHeader() {
    const dispatch = useAppDispatch();
    const [scrolled, setScrolled] = useState(false);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menuClasses = [''];
    if (scrolled) {
        menuClasses.push('scrolled transition');
    }

    return (
        <>
            <header className={`nv__header ${menuClasses.join(' ')}`}>
                <section className="nv__header__container">
                    <Animated animation={'animate__fadeInDown'}>
                        <div className="nv__header__logos">
                            {pathname !== '/ptg' &&
                                pathname !== '/panamerican' &&
                                pathname !== '/metro' &&
                                pathname !== '/donna-stella' &&
                                pathname !== '/harbor' &&
                                pathname !== '/ptyss' &&
                                pathname !== '/beermarkt' &&
                                pathname !== '/refeer-solutions' && (
                                    <>
                                        <div className="nv__header__logo">
                                            <Link to="/#home">
                                                <img
                                                    src={headerLogo}
                                                    alt="Logo Narval white blue"
                                                />
                                            </Link>
                                        </div>
                                    </>
                                )}
                            {pathname === '/ptg' && (
                                <div className="nv__header__logo__ptg ">
                                    <img src={PTGLogo} alt="Logo PTG " />
                                </div>
                            )}
                            {pathname === '/panamerican' && (
                                <div className="nv__header__logo__panamerican ">
                                    <img src={panamericanLogoWhite} alt="Logo Panamerican " />
                                </div>
                            )}
                            {pathname === '/metro' && (
                                <div className="nv__header__logo__panamerican ">
                                    <img src={metroLogo} alt="Logo Metro " />
                                </div>
                            )}
                            {pathname === '/donna-stella' && (
                                <div className="nv__header__logo__donna ">
                                    <img src={donnaStellaLogo} alt="Donna Stella" />
                                </div>
                            )}
                            {pathname === '/harbor' && (
                                <div className="nv__header__logo__panamerican ">
                                    <img src={harborLogo} alt="Harbor" />
                                </div>
                            )}
                            {pathname === '/ptyss' && (
                                <div className="nv__header__logo__panamerican ">
                                    <img src={ptyssLogo} alt="ptyss" />
                                </div>
                            )}
                            {pathname === '/refeer-solutions' && (
                                <div className="nv__header__logo__panamerican ">
                                    <img src={refeerLogo} alt="Panama Refeer Solutions " />
                                </div>
                            )}
                            {pathname === '/beermarkt' && (
                                <div className="nv__header__logo__beermarkt">
                                    <img src={beermarkt} alt="Beer Markt Logo" />
                                </div>
                            )}
                        </div>
                    </Animated>

                    <Animated animation={'animate__fadeInDown'}>
                        <Menu />
                    </Animated>
                    <Animated animation={'animate__fadeInDown'}>
                        <section className="nv__header__button__section">
                            <div
                                className="nv__header__menu nv__header__menu--mobile"
                                onClick={() => {
                                    dispatch(setDisplayMobileMenu(true));
                                }}
                            >
                                <IoMdMenu />
                            </div>
                            <ActionButton
                                text={t('menu.contact')}
                                type="rounded"
                                onClick={() => {
                                    dispatch(setDisplayModal(true));
                                }}
                            />

                            <LanguangeSelector />
                        </section>
                    </Animated>
                </section>
            </header>
        </>
    );
}
