import React, { useState } from 'react';
import { Drawer } from 'antd';
import { setDisplayMobileMenu } from 'reduxStore/slices/contact.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import headerLogo from 'assets/images/logos/logo-white-blue.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setDisplayModal } from 'reduxStore/slices/contact.slice';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { AppstoreOutlined, BranchesOutlined, DotChartOutlined } from '@ant-design/icons';
import { PiPlantFill } from 'react-icons/pi';
import { MdSell } from 'react-icons/md';

export default function MenuMovil() {
    const dispatch = useAppDispatch();
    const displayMobileMenu = useAppSelector((state) => state.contactSlice.displayMobileMenu);
    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    type MenuItem = Required<MenuProps>['items'][number];

    const items: MenuItem[] = [
        {
            key: 'item1',
            label: <Link to="/"> {t('menu.home')}</Link>,
        },
        {
            key: 'item2',
            label: <Link to="/services"> {t('menu.services')}</Link>,
        },
        {
            key: 'item3',
            label: <Link to="/about-us#about-us"> {t('menu.about')}</Link>,
        },
        {
            key: 'item4',
            label: <Link to="/history"> {t('menu.history')}</Link>,
        },
        {
            key: 'item5',
            label: (
                <Link
                    to="#"
                    onClick={() => {
                        onClose();
                        dispatch(setDisplayModal(true));
                    }}
                >
                    {' '}
                    {t('menu.contact')}
                </Link>
            ),
        },
        {
            key: 'sub1',
            label: t('preload.logistics'),
            icon: <DotChartOutlined />,
            children: [
                { key: '5', label: <Link to="/refeer-solutions">PRS</Link> },
                { key: '6', label: <Link to="/metro">METRO</Link> },
                { key: '7', label: <Link to="/ptyss">PTYSS</Link> },
                { key: '8', label: <Link to="/harbor">HSC</Link> },
                { key: '8', label: <Link to="/ptg">PTG</Link> },
            ],
        },
        {
            key: 'sub2',
            label: t('preload.technology'),
            icon: <BranchesOutlined />,
            children: [{ key: '9', label: <Link to="/panamerican">PLI</Link> }],
        },
        {
            key: 'sub3',
            label: t('preload.agro'),
            icon: <PiPlantFill />,
            children: [{ key: '9', label: <Link to="/donna-stella">DOÑA STELLA</Link> }],
        },

        {
            key: 'sub4',
            label: t('preload.retail'),
            icon: <MdSell />,
            children: [
                {
                    key: '9',
                    label: (
                        <Link to="https://beermarkt.com.pa/" target="_blank">
                            BEERMARKT
                        </Link>
                    ),
                },
            ],
        },
    ];

    const onClose = () => {
        dispatch(setDisplayMobileMenu(false));
    };

    return (
        <Drawer title="Basic Drawer" onClose={onClose} open={displayMobileMenu}>
            <div
                className="nv__header__logo nv__header__logo--mobile"
                onClick={() => {
                    onClose();
                }}
            >
                <Link to="/#home">
                    <img src={headerLogo} alt="Logo Narval white blue" />
                </Link>
            </div>

            <section>
                {/* <ActionButton
                    text={t('menu.contact')}
                    type="rounded"
                    onClick={() => {
                        onClose();
                        dispatch(setDisplayModal(true));
                    }}
                /> */}

                <Menu
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={collapsed}
                    items={items}
                    onClick={() => {
                        onClose();
                    }}
                />
            </section>
        </Drawer>
    );
}
