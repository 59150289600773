import React, { useEffect, useState } from 'react';

import { useGetPostQuery } from 'reduxStore/services/rss.services';
export default function BlogRSSs() {
    return (
        <>
            <main className="nv__section nv__blog">
                <div className="nv__blog__header"></div>
                <section className="nv__section__container">
                    <h1 className="nv__section__title text-center">BLOG</h1>

                    <section className="nv__blog__container">
                        <iframe
                            width="900"
                            height="1600"
                            src="https://rss.app/embed/v1/wall/FGLGl3kExxfJjgHZ"
                        ></iframe>
                    </section>
                </section>
            </main>
        </>
    );
}
