import React from 'react';
import { useTranslation } from 'react-i18next';

import BlockInfo from 'components/generals/block-info';

import medlog from 'assets/images/alliances/medlog.png';
import ascent from 'assets/images/alliances/ascent.png';
import msc from 'assets/images/alliances/msc.png';
import seaboard from 'assets/images/alliances/seaboard.png';
import dsci from 'assets/images/alliances/dsci.png';
import utp from 'assets/images/alliances/utp.png';
import usma from 'assets/images/alliances/usma.png';
import amcham from 'assets/images/alliances/amcham.png';
import basc from 'assets/images/alliances/basc.png';

const LogisticsItems = [
    {
        title: 'MEDLOG',
        link: 'https://www.medlog.com/en',
        button: 'logistic.button',
        imagesrc: medlog,
        isTranslate: false,
    },

    {
        title: 'ASCENT SOLUTIONS',
        link: 'https://www.myascents.com/',
        button: 'logistic.button',
        imagesrc: ascent,
        isTranslate: false,
    },

    {
        title: 'MSC',
        link: 'https://www.msc.com/en',
        button: 'logistic.button',
        imagesrc: msc,
        isTranslate: false,
    },

    {
        title: 'SEABOARD MARINE',
        link: 'https://www.seaboardmarine.com/',
        button: 'logistic.button',
        imagesrc: seaboard,
        isTranslate: false,
    },

    {
        title: 'DSCI',
        link: 'https://dscinstitute.org/',
        button: 'logistic.button',
        imagesrc: dsci,
        isTranslate: false,
    },

    {
        title: 'UTP',
        link: 'https://utp.ac.pa/',
        button: 'logistic.button',
        imagesrc: utp,
        isTranslate: false,
    },

    {
        title: 'USMA',
        link: 'https://usma.ac.pa/',
        button: 'logistic.button',
        imagesrc: usma,
        isTranslate: false,
    },

    {
        title: 'AMCHA',
        link: 'https://panamcham.com/es',
        button: 'logistic.button',
        imagesrc: amcham,
        isTranslate: false,
    },
    {
        title: 'BASC',
        link: 'https://wbasco.org/es',
        button: 'logistic.button',
        imagesrc: basc,
        isTranslate: false,
    },
];

export default function AlliancesSection() {
    const { t } = useTranslation();
    return (
        <main className="nv__section nv__blocks__info nv__blocks__info--top nv__alliances">
            <section className="nv__section__container">
                <h1 className="nv__section__title text-center text-white-color">
                    {t('alliances.title')}
                </h1>
                <p className="text-white-color">{t('alliances.description')}</p>
                <BlockInfo data={LogisticsItems} />
            </section>
        </main>
    );
}
