import { configureStore } from "@reduxjs/toolkit";
import calculatorSlice from "./slices/calcutator.slice";
import contactSlice from "./slices/contact.slice";
import componentSlice from "./slices/component.slide";
import postSlice from "./slices/post.slice";
import { postApi } from "./services/post.services";
import { RSSAPI } from "./services/rss.services";


export const store = configureStore({
    reducer: {
        calculatorSlice,
        contactSlice,
        postSlice,
        componentSlice, 
        [postApi.reducerPath]: postApi.reducer,
        [RSSAPI.reducerPath]: RSSAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([
            postApi.middleware,
            RSSAPI.middleware,
        ]),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
